@charset "UTF-8";
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Material Design Lite */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent on one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent on one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */
/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
html {
  color: rgba(0,0,0, 0.87);
  font-size: 1em;
  line-height: 1.4; }

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::selection {
  background: #b3d4fc;
  text-shadow: none; }

/*
 * A better looking default horizontal rule
 */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/*
 * Remove default fieldset styles.
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical; }

/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* ==========================================================================
   Author's custom styles
   ========================================================================== */
/* ==========================================================================
   Helper classes
   ========================================================================== */
/*
 * Hide visually and from screen readers:
 */
.hidden {
  display: none !important; }

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/*
 * Hide visually and from screen readers, but maintain layout
 */
.invisible {
  visibility: hidden; }

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.clearfix:after {
  clear: both; }

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */
@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */ }

@media print, (min-resolution: 1.25dppx), (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */ }

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */
@media print {
  *,
  *:before,
  *:after,
  *:first-letter {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Remove the unwanted box around FAB buttons */
/* More info: http://goo.gl/IPwKi */
a, .mdl-accordion, .mdl-button, .mdl-card, .mdl-checkbox, .mdl-dropdown-menu,
.mdl-icon-toggle, .mdl-item, .mdl-radio, .mdl-slider, .mdl-switch, .mdl-tabs__tab {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

/*
 * Make html take up the entire screen
 * Then set touch-action to avoid touch delay on mobile IE
 */
html {
  width: 100%;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

/*
* Make body take up the entire screen
* Remove body margin so layout containers don't cause extra overflow.
*/
body {
  width: 100%;
  min-height: 100%;
  margin: 0; }

/*
 * Main display reset for IE support.
 * Source: http://weblog.west-wind.com/posts/2015/Jan/12/main-HTML5-Tag-not-working-in-Internet-Explorer-91011
 */
main {
  display: block; }

/*
* Apply no display to elements with the hidden attribute.
* IE 9 and 10 support.
*/
*[hidden] {
  display: none !important; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent on one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
html, body {
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0; }

/**
  * Styles for HTML elements
  */
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
  font-size: 0.6em; }

h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  margin-top: 24px;
  margin-bottom: 24px; }

h2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  margin-top: 24px;
  margin-bottom: 24px; }

h3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 24px;
  margin-bottom: 24px; }

h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 24px;
  margin-bottom: 16px; }

h5 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 16px; }

h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  margin-top: 24px;
  margin-bottom: 16px; }

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 16px; }

a {
  color: rgb(230,81,0);
  font-weight: 500; }

blockquote {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  position: relative;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: 0.08em; }
  blockquote:before {
    position: absolute;
    left: -0.5em;
    content: '“'; }
  blockquote:after {
    content: '”';
    margin-left: -0.05em; }

mark {
  background-color: #f4ff81; }

dt {
  font-weight: 700; }

address {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  font-style: normal; }

ul, ol {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0; }

/**
 * Class Name Styles
 */
.mdl-typography--display-4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.04em; }

.mdl-typography--display-4-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.04em;
  opacity: 0.54; }

.mdl-typography--display-3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em; }

.mdl-typography--display-3-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54; }

.mdl-typography--display-2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px; }

.mdl-typography--display-2-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  opacity: 0.54; }

.mdl-typography--display-1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px; }

.mdl-typography--display-1-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  opacity: 0.54; }

.mdl-typography--headline {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale; }

.mdl-typography--headline-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.87; }

.mdl-typography--title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em; }

.mdl-typography--title-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  opacity: 0.87; }

.mdl-typography--subhead {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em; }

.mdl-typography--subhead-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  opacity: 0.87; }

.mdl-typography--body-2 {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0; }

.mdl-typography--body-2-color-contrast {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--body-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0; }

.mdl-typography--body-1-color-contrast {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--body-2-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0; }

.mdl-typography--body-2-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--body-1-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0; }

.mdl-typography--body-1-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0; }

.mdl-typography--caption-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0; }

.mdl-typography--caption-color-contrast {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.54; }

.mdl-typography--caption-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.54; }

.mdl-typography--menu {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0; }

.mdl-typography--menu-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--button {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0; }

.mdl-typography--button-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.87; }

.mdl-typography--text-left {
  text-align: left; }

.mdl-typography--text-right {
  text-align: right; }

.mdl-typography--text-center {
  text-align: center; }

.mdl-typography--text-justify {
  text-align: justify; }

.mdl-typography--text-nowrap {
  white-space: nowrap; }

.mdl-typography--text-lowercase {
  text-transform: lowercase; }

.mdl-typography--text-uppercase {
  text-transform: uppercase; }

.mdl-typography--text-capitalize {
  text-transform: capitalize; }

.mdl-typography--font-thin {
  font-weight: 200 !important; }

.mdl-typography--font-light {
  font-weight: 300 !important; }

.mdl-typography--font-regular {
  font-weight: 400 !important; }

.mdl-typography--font-medium {
  font-weight: 500 !important; }

.mdl-typography--font-bold {
  font-weight: 700 !important; }

.mdl-typography--font-black {
  font-weight: 900 !important; }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent on one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-button {
  background: transparent;
  border: none;
  border-radius: 2px;
  color: rgb(0,0,0);
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle; }
  .mdl-button::-moz-focus-inner {
    border: 0; }
  .mdl-button:hover {
    background-color: rgba(158,158,158, 0.20); }
  .mdl-button:focus:not(:active) {
    background-color: rgba(0,0,0, 0.12); }
  .mdl-button:active {
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button.mdl-button--colored {
    color: rgb(250,250,250); }
    .mdl-button.mdl-button--colored:focus:not(:active) {
      background-color: rgba(0,0,0, 0.12); }

input.mdl-button[type="submit"] {
  -webkit-appearance: none; }

.mdl-button--raised {
  background: rgba(158,158,158, 0.20);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .mdl-button--raised:active {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button--raised:focus:not(:active) {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button--raised.mdl-button--colored {
    background: rgb(250,250,250);
    color: rgb(255,255,255); }
    .mdl-button--raised.mdl-button--colored:hover {
      background-color: rgb(250,250,250); }
    .mdl-button--raised.mdl-button--colored:active {
      background-color: rgb(250,250,250); }
    .mdl-button--raised.mdl-button--colored:focus:not(:active) {
      background-color: rgb(250,250,250); }
    .mdl-button--raised.mdl-button--colored .mdl-ripple {
      background: rgb(255,255,255); }

.mdl-button--fab {
  border-radius: 50%;
  font-size: 24px;
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  padding: 0;
  overflow: hidden;
  background: rgba(158,158,158, 0.20);
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  line-height: normal; }
  .mdl-button--fab .material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    line-height: 24px;
    width: 24px; }
  .mdl-button--fab.mdl-button--mini-fab {
    height: 40px;
    min-width: 40px;
    width: 40px; }
  .mdl-button--fab .mdl-button__ripple-container {
    border-radius: 50%;
    -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }
  .mdl-button--fab:active {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button--fab:focus:not(:active) {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
    background-color: rgba(158,158,158, 0.40); }
  .mdl-button--fab.mdl-button--colored {
    background: rgb(230,81,0);
    color: rgb(255,255,255); }
    .mdl-button--fab.mdl-button--colored:hover {
      background-color: rgb(230,81,0); }
    .mdl-button--fab.mdl-button--colored:focus:not(:active) {
      background-color: rgb(230,81,0); }
    .mdl-button--fab.mdl-button--colored:active {
      background-color: rgb(230,81,0); }
    .mdl-button--fab.mdl-button--colored .mdl-ripple {
      background: rgb(255,255,255); }

.mdl-button--icon {
  border-radius: 50%;
  font-size: 24px;
  height: 32px;
  margin-left: 0;
  margin-right: 0;
  min-width: 32px;
  width: 32px;
  padding: 0;
  overflow: hidden;
  color: inherit;
  line-height: normal; }
  .mdl-button--icon .material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    line-height: 24px;
    width: 24px; }
  .mdl-button--icon.mdl-button--mini-icon {
    height: 24px;
    min-width: 24px;
    width: 24px; }
    .mdl-button--icon.mdl-button--mini-icon .material-icons {
      top: 0px;
      left: 0px; }
  .mdl-button--icon .mdl-button__ripple-container {
    border-radius: 50%;
    -webkit-mask-image: -webkit-radial-gradient(circle, white, black); }

.mdl-button__ripple-container {
  display: block;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
  overflow: hidden; }
  .mdl-button[disabled] .mdl-button__ripple-container .mdl-ripple,
  .mdl-button.mdl-button--disabled .mdl-button__ripple-container .mdl-ripple {
    background-color: transparent; }

.mdl-button--primary.mdl-button--primary {
  color: rgb(250,250,250); }
  .mdl-button--primary.mdl-button--primary .mdl-ripple {
    background: rgb(255,255,255); }
  .mdl-button--primary.mdl-button--primary.mdl-button--raised, .mdl-button--primary.mdl-button--primary.mdl-button--fab {
    color: rgb(255,255,255);
    background-color: rgb(250,250,250); }

.mdl-button--accent.mdl-button--accent {
  color: rgb(230,81,0); }
  .mdl-button--accent.mdl-button--accent .mdl-ripple {
    background: rgb(255,255,255); }
  .mdl-button--accent.mdl-button--accent.mdl-button--raised, .mdl-button--accent.mdl-button--accent.mdl-button--fab {
    color: rgb(255,255,255);
    background-color: rgb(230,81,0); }

.mdl-button[disabled][disabled], .mdl-button.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0,0,0, 0.26);
  cursor: default;
  background-color: transparent; }

.mdl-button--fab[disabled][disabled], .mdl-button--fab.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26); }

.mdl-button--raised[disabled][disabled], .mdl-button--raised.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26);
  box-shadow: none; }

.mdl-button--colored[disabled][disabled], .mdl-button--colored.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0,0,0, 0.26); }

.mdl-button .material-icons {
  vertical-align: middle; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent on one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-card {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  width: 330px;
  z-index: 1;
  position: relative;
  background: rgb(255,255,255);
  border-radius: 2px;
  box-sizing: border-box; }

.mdl-card__media {
  background-color: rgb(230,81,0);
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-origin: padding-box;
  background-attachment: scroll;
  box-sizing: border-box; }

.mdl-card__title {
  align-items: center;
  color: rgb(0,0,0);
  display: block;
  display: flex;
  line-height: normal;
  padding: 16px 16px;
  perspective-origin: 165px 56px;
  transform-origin: 165px 56px;
  box-sizing: border-box; }
  .mdl-card__title.mdl-card--border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.mdl-card__title-text {
  align-self: flex-end;
  color: inherit;
  display: block;
  display: flex;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  overflow: hidden;
  transform-origin: 149px 48px;
  margin: 0; }

.mdl-card__subtitle-text {
  font-size: 14px;
  color: rgba(0,0,0, 0.54);
  margin: 0; }

.mdl-card__supporting-text {
  color: rgba(0,0,0, 0.54);
  font-size: 1rem;
  line-height: 18px;
  overflow: hidden;
  padding: 16px 16px;
  width: 90%; }
  .mdl-card__supporting-text.mdl-card--border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.mdl-card__actions {
  font-size: 16px;
  line-height: normal;
  width: 100%;
  background-color: transparent;
  padding: 8px;
  box-sizing: border-box; }
  .mdl-card__actions.mdl-card--border {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }

.mdl-card--expand {
  flex-grow: 1; }

.mdl-card__menu {
  position: absolute;
  right: 16px;
  top: 16px; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent on one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-navigation {
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box; }

.mdl-navigation__link {
  color: rgb(66,66,66);
  text-decoration: none;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87; }
  .mdl-navigation__link .material-icons {
    vertical-align: middle; }

.mdl-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch; }

.mdl-layout.is-small-screen .mdl-layout--large-screen-only {
  display: none; }

.mdl-layout:not(.is-small-screen) .mdl-layout--small-screen-only {
  display: none; }

.mdl-layout__container {
  position: absolute;
  width: 100%;
  height: 100%; }

.mdl-layout__title,
.mdl-layout-title {
  display: block;
  position: relative;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  font-weight: 400;
  box-sizing: border-box; }

.mdl-layout-spacer {
  flex-grow: 1; }

.mdl-layout__drawer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 240px;
  height: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-right: 1px solid rgb(224,224,224);
  background: rgb(250,250,250);
  transform: translateX(-250px);
  transform-style: preserve-3d;
  will-change: transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  color: rgb(66,66,66);
  overflow: visible;
  overflow-y: auto;
  z-index: 5; }
  .mdl-layout__drawer.is-visible {
    transform: translateX(0); }
    .mdl-layout__drawer.is-visible ~ .mdl-layout__content.mdl-layout__content {
      overflow: hidden; }
  .mdl-layout__drawer > * {
    flex-shrink: 0; }
  .mdl-layout__drawer > .mdl-layout__title,
  .mdl-layout__drawer > .mdl-layout-title {
    line-height: 64px;
    padding-left: 40px; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__drawer > .mdl-layout__title,
      .mdl-layout__drawer > .mdl-layout-title {
        line-height: 56px;
        padding-left: 16px; } }
  .mdl-layout__drawer .mdl-navigation {
    flex-direction: column;
    align-items: stretch;
    padding-top: 16px; }
    .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
      display: block;
      flex-shrink: 0;
      padding: 16px 40px;
      margin: 0;
      color: #757575; }
      @media screen and (max-width: 1024px) {
        .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
          padding: 16px 16px; } }
      .mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
        background-color: rgb(224,224,224); }
      .mdl-layout__drawer .mdl-navigation .mdl-navigation__link--current {
        background-color: rgb(224,224,224);
        color: rgb(0,0,0); }
  @media screen and (min-width: 1025px) {
    .mdl-layout--fixed-drawer > .mdl-layout__drawer {
      transform: translateX(0); } }

.mdl-layout__drawer-button {
  display: block;
  position: absolute;
  height: 48px;
  width: 48px;
  border: 0;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  line-height: 56px;
  font-family: Helvetica, Arial, sans-serif;
  margin: 8px 12px;
  top: 0;
  left: 0;
  color: rgb(255,255,255);
  z-index: 4; }
  .mdl-layout__header .mdl-layout__drawer-button {
    position: absolute;
    color: rgb(255,255,255);
    background-color: inherit; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header .mdl-layout__drawer-button {
        margin: 4px; } }
  @media screen and (max-width: 1024px) {
    .mdl-layout__drawer-button {
      margin: 4px;
      color: rgba(0, 0, 0, 0.5); } }
  @media screen and (min-width: 1025px) {
    .mdl-layout__drawer-button {
      line-height: 54px; }
      .mdl-layout--no-desktop-drawer-button .mdl-layout__drawer-button,
      .mdl-layout--fixed-drawer > .mdl-layout__drawer-button,
      .mdl-layout--no-drawer-button .mdl-layout__drawer-button {
        display: none; } }

.mdl-layout__header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  min-height: 64px;
  max-height: 1000px;
  z-index: 3;
  background-color: rgb(250,250,250);
  color: rgb(255,255,255);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: max-height, box-shadow; }
  @media screen and (max-width: 1024px) {
    .mdl-layout__header {
      min-height: 56px; } }
  .mdl-layout--fixed-drawer.is-upgraded:not(.is-small-screen) > .mdl-layout__header {
    margin-left: 240px;
    width: calc(100% - 240px); }
  @media screen and (min-width: 1025px) {
    .mdl-layout--fixed-drawer > .mdl-layout__header .mdl-layout__header-row {
      padding-left: 40px; } }
  .mdl-layout__header > .mdl-layout-icon {
    position: absolute;
    left: 40px;
    top: 16px;
    height: 32px;
    width: 32px;
    overflow: hidden;
    z-index: 3;
    display: block; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header > .mdl-layout-icon {
        left: 16px;
        top: 12px; } }
  .mdl-layout.has-drawer .mdl-layout__header > .mdl-layout-icon {
    display: none; }
  .mdl-layout__header.is-compact {
    max-height: 64px; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header.is-compact {
        max-height: 56px; } }
  .mdl-layout__header.is-compact.has-tabs {
    height: 112px; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header.is-compact.has-tabs {
        min-height: 104px; } }
  @media screen and (max-width: 1024px) {
    .mdl-layout__header {
      display: none; }
    .mdl-layout--fixed-header > .mdl-layout__header {
      display: flex; } }

.mdl-layout__header--transparent.mdl-layout__header--transparent {
  background-color: transparent;
  box-shadow: none; }

.mdl-layout__header--seamed {
  box-shadow: none; }

.mdl-layout__header--scroll {
  box-shadow: none; }

.mdl-layout__header--waterfall {
  box-shadow: none;
  overflow: hidden; }
  .mdl-layout__header--waterfall.is-casting-shadow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .mdl-layout__header--waterfall.mdl-layout__header--waterfall-hide-top {
    justify-content: flex-end; }

.mdl-layout__header-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 0;
  box-sizing: border-box;
  align-self: stretch;
  align-items: center;
  height: 64px;
  margin: 0;
  padding: 0 40px 0 80px; }
  .mdl-layout--no-drawer-button .mdl-layout__header-row {
    padding-left: 40px; }
  @media screen and (min-width: 1025px) {
    .mdl-layout--no-desktop-drawer-button .mdl-layout__header-row {
      padding-left: 40px; } }
  @media screen and (max-width: 1024px) {
    .mdl-layout__header-row {
      height: 56px;
      padding: 0 16px 0 72px; }
      .mdl-layout--no-drawer-button .mdl-layout__header-row {
        padding-left: 16px; } }
  .mdl-layout__header-row > * {
    flex-shrink: 0; }
  .mdl-layout__header--scroll .mdl-layout__header-row {
    width: 100%; }
  .mdl-layout__header-row .mdl-navigation {
    margin: 0;
    padding: 0;
    height: 64px;
    flex-direction: row;
    align-items: center; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header-row .mdl-navigation {
        height: 56px; } }
  .mdl-layout__header-row .mdl-navigation__link {
    display: block;
    color: rgb(255,255,255);
    line-height: 64px;
    padding: 0 24px; }
    @media screen and (max-width: 1024px) {
      .mdl-layout__header-row .mdl-navigation__link {
        line-height: 56px;
        padding: 0 16px; } }

.mdl-layout__obfuscator {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  visibility: hidden;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  .mdl-layout__obfuscator.is-visible {
    background-color: rgba(0, 0, 0, 0.5);
    visibility: visible; }
  @supports (pointer-events: auto) {
    .mdl-layout__obfuscator {
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition-property: opacity;
      visibility: visible;
      pointer-events: none; }
      .mdl-layout__obfuscator.is-visible {
        pointer-events: auto;
        opacity: 1; } }

.mdl-layout__content {
  -ms-flex: 0 1 auto;
  position: relative;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  z-index: 1;
  -webkit-overflow-scrolling: touch; }
  .mdl-layout--fixed-drawer > .mdl-layout__content {
    margin-left: 240px; }
  .mdl-layout__container.has-scrolling-header .mdl-layout__content {
    overflow: visible; }
  @media screen and (max-width: 1024px) {
    .mdl-layout--fixed-drawer > .mdl-layout__content {
      margin-left: 0; }
    .mdl-layout__container.has-scrolling-header .mdl-layout__content {
      overflow-y: auto;
      overflow-x: hidden; } }

.mdl-layout__tab-bar {
  height: 96px;
  margin: 0;
  width: calc(100% - 112px);
  padding: 0 0 0 56px;
  display: flex;
  background-color: rgb(250,250,250);
  overflow-y: hidden;
  overflow-x: scroll; }
  .mdl-layout__tab-bar::-webkit-scrollbar {
    display: none; }
  .mdl-layout--no-drawer-button .mdl-layout__tab-bar {
    padding-left: 16px;
    width: calc(100% - 32px); }
  @media screen and (min-width: 1025px) {
    .mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar {
      padding-left: 16px;
      width: calc(100% - 32px); } }
  @media screen and (max-width: 1024px) {
    .mdl-layout__tab-bar {
      width: calc(100% - 60px);
      padding: 0 0 0 60px; }
      .mdl-layout--no-drawer-button .mdl-layout__tab-bar {
        width: calc(100% - 8px);
        padding-left: 4px; } }
  .mdl-layout--fixed-tabs .mdl-layout__tab-bar {
    padding: 0;
    overflow: hidden;
    width: 100%; }

.mdl-layout__tab-bar-container {
  position: relative;
  height: 48px;
  width: 100%;
  border: none;
  margin: 0;
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden; }
  .mdl-layout__container > .mdl-layout__tab-bar-container {
    position: absolute;
    top: 0;
    left: 0; }

.mdl-layout__tab-bar-button {
  display: inline-block;
  position: absolute;
  top: 0;
  height: 48px;
  width: 56px;
  z-index: 4;
  text-align: center;
  background-color: rgb(250,250,250);
  color: transparent;
  cursor: pointer;
  user-select: none; }
  .mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button,
  .mdl-layout--no-drawer-button .mdl-layout__tab-bar-button {
    width: 16px; }
    .mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button .material-icons,
    .mdl-layout--no-drawer-button .mdl-layout__tab-bar-button .material-icons {
      position: relative;
      left: -4px; }
  @media screen and (max-width: 1024px) {
    .mdl-layout__tab-bar-button {
      width: 60px; } }
  .mdl-layout--fixed-tabs .mdl-layout__tab-bar-button {
    display: none; }
  .mdl-layout__tab-bar-button .material-icons {
    line-height: 48px; }
  .mdl-layout__tab-bar-button.is-active {
    color: rgb(255,255,255); }

.mdl-layout__tab-bar-left-button {
  left: 0; }

.mdl-layout__tab-bar-right-button {
  right: 0; }

.mdl-layout__tab {
  margin: 0;
  border: none;
  padding: 0 24px 0 24px;
  float: left;
  position: relative;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(255,255,255, 0.6);
  overflow: hidden; }
  @media screen and (max-width: 1024px) {
    .mdl-layout__tab {
      padding: 0 12px 0 12px; } }
  .mdl-layout--fixed-tabs .mdl-layout__tab {
    float: none;
    flex-grow: 1;
    padding: 0; }
  .mdl-layout.is-upgraded .mdl-layout__tab.is-active {
    color: rgb(255,255,255); }
  .mdl-layout.is-upgraded .mdl-layout__tab.is-active::after {
    height: 2px;
    width: 100%;
    display: block;
    content: " ";
    bottom: 0;
    left: 0;
    position: absolute;
    background: rgb(230,81,0);
    animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) alternate forwards;
    transition: all 1s cubic-bezier(0.4, 0, 1, 1); }
  .mdl-layout__tab .mdl-layout__tab-ripple-container {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden; }
    .mdl-layout__tab .mdl-layout__tab-ripple-container .mdl-ripple {
      background-color: rgb(255,255,255); }

.mdl-layout__tab-panel {
  display: block; }
  .mdl-layout.is-upgraded .mdl-layout__tab-panel {
    display: none; }
  .mdl-layout.is-upgraded .mdl-layout__tab-panel.is-active {
    display: block; }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent on one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-shadow--2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.mdl-shadow--3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12); }

.mdl-shadow--4dp {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }

.mdl-shadow--6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2); }

.mdl-shadow--8dp {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }

.mdl-shadow--16dp {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

.mdl-shadow--24dp {
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2); }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
* NOTE: Some rules here are applied using duplicate selectors.
* This is on purpose to increase their specificity when applied.
* For example: `.mdl-cell--1-col-phone.mdl-cell--1-col-phone`
*/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent on one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto 0 auto;
  align-items: stretch; }
  .mdl-grid.mdl-grid--no-spacing {
    padding: 0; }

.mdl-cell {
  box-sizing: border-box; }

.mdl-cell--top {
  align-self: flex-start; }

.mdl-cell--middle {
  align-self: center; }

.mdl-cell--bottom {
  align-self: flex-end; }

.mdl-cell--stretch {
  align-self: stretch; }

.mdl-grid.mdl-grid--no-spacing > .mdl-cell {
  margin: 0; }

.mdl-cell--order-1 {
  order: 1; }

.mdl-cell--order-2 {
  order: 2; }

.mdl-cell--order-3 {
  order: 3; }

.mdl-cell--order-4 {
  order: 4; }

.mdl-cell--order-5 {
  order: 5; }

.mdl-cell--order-6 {
  order: 6; }

.mdl-cell--order-7 {
  order: 7; }

.mdl-cell--order-8 {
  order: 8; }

.mdl-cell--order-9 {
  order: 9; }

.mdl-cell--order-10 {
  order: 10; }

.mdl-cell--order-11 {
  order: 11; }

.mdl-cell--order-12 {
  order: 12; }

@media (max-width: 479px) {
  .mdl-grid {
    padding: 8px; }
  .mdl-cell {
    margin: 8px;
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell {
      width: 100%; }
  .mdl-cell--hide-phone {
    display: none !important; }
  .mdl-cell--order-1-phone.mdl-cell--order-1-phone {
    order: 1; }
  .mdl-cell--order-2-phone.mdl-cell--order-2-phone {
    order: 2; }
  .mdl-cell--order-3-phone.mdl-cell--order-3-phone {
    order: 3; }
  .mdl-cell--order-4-phone.mdl-cell--order-4-phone {
    order: 4; }
  .mdl-cell--order-5-phone.mdl-cell--order-5-phone {
    order: 5; }
  .mdl-cell--order-6-phone.mdl-cell--order-6-phone {
    order: 6; }
  .mdl-cell--order-7-phone.mdl-cell--order-7-phone {
    order: 7; }
  .mdl-cell--order-8-phone.mdl-cell--order-8-phone {
    order: 8; }
  .mdl-cell--order-9-phone.mdl-cell--order-9-phone {
    order: 9; }
  .mdl-cell--order-10-phone.mdl-cell--order-10-phone {
    order: 10; }
  .mdl-cell--order-11-phone.mdl-cell--order-11-phone {
    order: 11; }
  .mdl-cell--order-12-phone.mdl-cell--order-12-phone {
    order: 12; }
  .mdl-cell--1-col,
  .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: calc(25% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing >
    .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
      width: 25%; }
  .mdl-cell--2-col,
  .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: calc(50% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing >
    .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
      width: 50%; }
  .mdl-cell--3-col,
  .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: calc(75% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing >
    .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
      width: 75%; }
  .mdl-cell--4-col,
  .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing >
    .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
      width: 100%; }
  .mdl-cell--5-col,
  .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing >
    .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
      width: 100%; }
  .mdl-cell--6-col,
  .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing >
    .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
      width: 100%; }
  .mdl-cell--7-col,
  .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing >
    .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
      width: 100%; }
  .mdl-cell--8-col,
  .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing >
    .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
      width: 100%; }
  .mdl-cell--9-col,
  .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing >
    .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
      width: 100%; }
  .mdl-cell--10-col,
  .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing >
    .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
      width: 100%; }
  .mdl-cell--11-col,
  .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing >
    .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
      width: 100%; }
  .mdl-cell--12-col,
  .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing >
    .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
      width: 100%; }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: calc(25% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
      margin-left: 25%; }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: calc(50% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
      margin-left: 50%; }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: calc(75% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
      margin-left: 75%; } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdl-grid {
    padding: 8px; }
  .mdl-cell {
    margin: 8px;
    width: calc(50% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell {
      width: 50%; }
  .mdl-cell--hide-tablet {
    display: none !important; }
  .mdl-cell--order-1-tablet.mdl-cell--order-1-tablet {
    order: 1; }
  .mdl-cell--order-2-tablet.mdl-cell--order-2-tablet {
    order: 2; }
  .mdl-cell--order-3-tablet.mdl-cell--order-3-tablet {
    order: 3; }
  .mdl-cell--order-4-tablet.mdl-cell--order-4-tablet {
    order: 4; }
  .mdl-cell--order-5-tablet.mdl-cell--order-5-tablet {
    order: 5; }
  .mdl-cell--order-6-tablet.mdl-cell--order-6-tablet {
    order: 6; }
  .mdl-cell--order-7-tablet.mdl-cell--order-7-tablet {
    order: 7; }
  .mdl-cell--order-8-tablet.mdl-cell--order-8-tablet {
    order: 8; }
  .mdl-cell--order-9-tablet.mdl-cell--order-9-tablet {
    order: 9; }
  .mdl-cell--order-10-tablet.mdl-cell--order-10-tablet {
    order: 10; }
  .mdl-cell--order-11-tablet.mdl-cell--order-11-tablet {
    order: 11; }
  .mdl-cell--order-12-tablet.mdl-cell--order-12-tablet {
    order: 12; }
  .mdl-cell--1-col,
  .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: calc(12.5% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing >
    .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
      width: 12.5%; }
  .mdl-cell--2-col,
  .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: calc(25% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing >
    .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
      width: 25%; }
  .mdl-cell--3-col,
  .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: calc(37.5% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing >
    .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
      width: 37.5%; }
  .mdl-cell--4-col,
  .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: calc(50% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing >
    .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
      width: 50%; }
  .mdl-cell--5-col,
  .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: calc(62.5% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing >
    .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
      width: 62.5%; }
  .mdl-cell--6-col,
  .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: calc(75% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing >
    .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
      width: 75%; }
  .mdl-cell--7-col,
  .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: calc(87.5% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing >
    .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
      width: 87.5%; }
  .mdl-cell--8-col,
  .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing >
    .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
      width: 100%; }
  .mdl-cell--9-col,
  .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing >
    .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
      width: 100%; }
  .mdl-cell--10-col,
  .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing >
    .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
      width: 100%; }
  .mdl-cell--11-col,
  .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing >
    .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
      width: 100%; }
  .mdl-cell--12-col,
  .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing >
    .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
      width: 100%; }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: calc(12.5% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
      margin-left: 12.5%; }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: calc(25% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
      margin-left: 25%; }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: calc(37.5% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
      margin-left: 37.5%; }
  .mdl-cell--4-offset,
  .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: calc(50% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
      margin-left: 50%; }
  .mdl-cell--5-offset,
  .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: calc(62.5% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
      margin-left: 62.5%; }
  .mdl-cell--6-offset,
  .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: calc(75% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
      margin-left: 75%; }
  .mdl-cell--7-offset,
  .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: calc(87.5% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
      margin-left: 87.5%; } }

@media (min-width: 840px) {
  .mdl-grid {
    padding: 8px; }
  .mdl-cell {
    margin: 8px;
    width: calc(33.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell {
      width: 33.3333333333%; }
  .mdl-cell--hide-desktop {
    display: none !important; }
  .mdl-cell--order-1-desktop.mdl-cell--order-1-desktop {
    order: 1; }
  .mdl-cell--order-2-desktop.mdl-cell--order-2-desktop {
    order: 2; }
  .mdl-cell--order-3-desktop.mdl-cell--order-3-desktop {
    order: 3; }
  .mdl-cell--order-4-desktop.mdl-cell--order-4-desktop {
    order: 4; }
  .mdl-cell--order-5-desktop.mdl-cell--order-5-desktop {
    order: 5; }
  .mdl-cell--order-6-desktop.mdl-cell--order-6-desktop {
    order: 6; }
  .mdl-cell--order-7-desktop.mdl-cell--order-7-desktop {
    order: 7; }
  .mdl-cell--order-8-desktop.mdl-cell--order-8-desktop {
    order: 8; }
  .mdl-cell--order-9-desktop.mdl-cell--order-9-desktop {
    order: 9; }
  .mdl-cell--order-10-desktop.mdl-cell--order-10-desktop {
    order: 10; }
  .mdl-cell--order-11-desktop.mdl-cell--order-11-desktop {
    order: 11; }
  .mdl-cell--order-12-desktop.mdl-cell--order-12-desktop {
    order: 12; }
  .mdl-cell--1-col,
  .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: calc(8.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing >
    .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
      width: 8.3333333333%; }
  .mdl-cell--2-col,
  .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: calc(16.6666666667% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing >
    .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
      width: 16.6666666667%; }
  .mdl-cell--3-col,
  .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: calc(25% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing >
    .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
      width: 25%; }
  .mdl-cell--4-col,
  .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: calc(33.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing >
    .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
      width: 33.3333333333%; }
  .mdl-cell--5-col,
  .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: calc(41.6666666667% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing >
    .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
      width: 41.6666666667%; }
  .mdl-cell--6-col,
  .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: calc(50% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing >
    .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
      width: 50%; }
  .mdl-cell--7-col,
  .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: calc(58.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing >
    .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
      width: 58.3333333333%; }
  .mdl-cell--8-col,
  .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: calc(66.6666666667% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing >
    .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
      width: 66.6666666667%; }
  .mdl-cell--9-col,
  .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: calc(75% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing >
    .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
      width: 75%; }
  .mdl-cell--10-col,
  .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: calc(83.3333333333% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing >
    .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
      width: 83.3333333333%; }
  .mdl-cell--11-col,
  .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: calc(91.6666666667% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing >
    .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
      width: 91.6666666667%; }
  .mdl-cell--12-col,
  .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: calc(100% - 16px); }
    .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing >
    .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
      width: 100%; }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: calc(8.3333333333% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
      margin-left: 8.3333333333%; }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: calc(16.6666666667% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
      margin-left: 16.6666666667%; }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: calc(25% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
      margin-left: 25%; }
  .mdl-cell--4-offset,
  .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: calc(33.3333333333% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
      margin-left: 33.3333333333%; }
  .mdl-cell--5-offset,
  .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: calc(41.6666666667% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
      margin-left: 41.6666666667%; }
  .mdl-cell--6-offset,
  .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: calc(50% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
      margin-left: 50%; }
  .mdl-cell--7-offset,
  .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: calc(58.3333333333% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
      margin-left: 58.3333333333%; }
  .mdl-cell--8-offset,
  .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: calc(66.6666666667% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
      margin-left: 66.6666666667%; }
  .mdl-cell--9-offset,
  .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: calc(75% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
      margin-left: 75%; }
  .mdl-cell--10-offset,
  .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: calc(83.3333333333% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
      margin-left: 83.3333333333%; }
  .mdl-cell--11-offset,
  .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: calc(91.6666666667% + 8px); }
    .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset, .mdl-grid.mdl-grid--no-spacing >
    .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
      margin-left: 91.6666666667%; } }
